import React from "react";
import { Helmet } from "react-helmet";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { ChakraProvider } from "@chakra-ui/react";
import customTheme from "../utils/theme";
import Fonts from "../components/Fonts";

import FranchiseForm from "../components/FranchiseFormPage/FranchiseForm";



const FranchiseFormPage = () => {
  return (
    <ChakraProvider theme={customTheme}>
      <Helmet>
        <html lang="en" />
        <meta charSet="utf-8" />
        <title>Milksha Australia - Franchise Form</title>
        <meta name="description" content="Milksha Franchise Form" />
      </Helmet>
      <Fonts />
      <Header />

      
      <FranchiseForm />

      <Footer />
    </ChakraProvider>
  );
};

export default FranchiseFormPage;

import React from "react";
import {
  Flex,
  VStack,
  Stack,
  Text,
  useBreakpointValue,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  Select,
  Container,
  NumberInput,
  NumberInputField,
  RadioGroup,
  Radio,
  HStack,
} from "@chakra-ui/react";



import "./franchise_form.css";

import form_page_bg from '../../images/franchise/form_page_bg.png'



export default function FranchiseForm() {
  return (
    <Flex 
      w={"full"} 
      fontFamily={"extrabold"} 
      m={0} 
      textAlign={"left"}
      bgImage={form_page_bg}
      bgRepeat={'no-repeat'}
      backgroundSize={'contain'}
      bgPosition={'right bottom'}
    >
      <Container  
        maxW={"4xl"}
        align={"center"}
        justifyContent={'center'}
        boxShadow={'dark-lg'}
        p={10}
        m={['0', '10', '0 auto']}
        my={10}
        borderRadius={"20px"}
      >
        <form method="post" action="https://formspree.io/f/xwkarwal">
          <Stack direction={["column", "row"]} spacing="10px">
            <FormControl id="fullName" isRequired>
              <FormLabel color={"primary.100"}>Full Name</FormLabel>
              <Input name="fullName" type="text" placeholder="Full Name" />
            </FormControl>
            <FormControl id="mobileNumber" isRequired>
              <FormLabel color={"primary.100"}>Mobile Number</FormLabel>
              <Input name="mobileNumber" type="tel" placeholder="Mobile Number" />
            </FormControl>
          </Stack>
          <Stack direction={["column", "row"]} spacing="10px" pt={5}>
            <FormControl id="email" isRequired>
              <FormLabel color={"primary.100"}>Email Address</FormLabel>
              <Input
                type="email"
                placeholder="Email Address"
                name="_replyto"
              />
            </FormControl>
            <FormControl id="state">
              <Text
                color={"primary.100"}
                fontSize={"1rem"}
                fontWeight={"500"}
                textAlign={"left"}
                mb={'8px'}
              >
                State
              </Text>
              <Select placeholder="State" name="state">
                <option value="New South Wales">New South Wales</option>
                <option value="Victoria">Victoria</option>
                <option value="Queensland">Queensland</option>
                <option value="South Australia">South Australia</option>
                <option value="Tasmania">Tasmania</option>
                <option value="Western Australia">Western Australia</option>
                <option value="Australian Capital Territory">
                  Australian Capital Territory
                </option>
                <option value="Northern Territory">Northern Territory</option>
              </Select>
            </FormControl>
          </Stack>
          <FormControl id="investmentBudget" py={5} isRequired>
            <FormLabel
              color={"primary.100"}
              fontSize={"1rem"}
              fontWeight={"500"}
              textAlign={"left"}
              mb={'8px'}
            >
              Investment Budget
            </FormLabel>
            <Select placeholder="Investment Budget" name="investmentBudget">
              <option value="300,000AUD~400,000AUD">300,000AUD~400,000AUD</option>
              <option value="400,000AUD~500,000AUD">400,000AUD~500,000AUD</option>
              <option value="others">others</option>
            </Select>
            </FormControl>
            <FormControl pb={5}>
            <FormLabel>If others, kindly indicate the specific amounts</FormLabel>
            <NumberInput id="investment_budget_value" name="investment_budget_value">
              <NumberInputField />
            </NumberInput>
          </FormControl>
          {/* <FormControl as='fieldset' isRequired>
            <FormLabel as='legend'>
              Will you attend the Franchise Conference? / 是否參加說明會?
            </FormLabel>
            <RadioGroup defaultValue='Yes' id="attend_franchise_conference" name="attend_franchise_conference">
              <HStack spacing='24px'>
                <Radio value='Yes'>Yes</Radio>
                <Radio value='No'>No</Radio>
              </HStack>
            </RadioGroup>
          </FormControl> */}
          {/* <FormControl as='fieldset' mt={5}>
            <FormLabel as='legend'>
              Select a session / 場次
            </FormLabel>
            <RadioGroup defaultValue='11/24 19:00' id="selected_session" name="selected_session">
              <HStack spacing='24px'>
                <Radio value='11/24 19:00'>11/24 19:00</Radio>
                <Radio value='11/25 10:00'>11/25 10:00</Radio>
                <Radio value='11/26 10:00'>11/26 10:00</Radio>
              </HStack>
            </RadioGroup>
          </FormControl> */}
          <FormControl id="message" pt={0}>
            <Text
              pb={2}
              color={"primary.100"}
              fontSize={"1rem"}
              fontWeight={"500"}
              textAlign={"left"}
            >
              Message
            </Text>
            <Textarea name="message" />
          </FormControl>
          <Button loadingText="Sending" variant="solid" mt={4} type="submit">
            Send
          </Button>
        </form>
      </Container>
      
    </Flex>
  );
}
